/* Форма */
$placeholder-color = #222


.form
    &-input   /* input */
        width 100%
        height 40px
        border 1px solid #282723
        background none
        color #222
        display block
        margin 0 0 20px
        padding 0 10px
        font 300 15px/40px 'Roboto Slab', serif
        outline none
        &.error
            color red !important
            border 1px solid red !important
        &.valid
            border 1px solid #339E36 !important            
        &:focus
            color #000
        &::-webkit-input-placeholder 
            color $placeholder-color
        &::-moz-placeholder  
            color $placeholder-color /* Firefox 19+ */
        &:-moz-placeholder           
            color $placeholder-color /* Firefox 18- */
        &:-ms-input-placeholder
            color $placeholder-color
    &-label
        position relative 
    &-submit
        -webkit-appearance none
            
.wpcf7-not-valid-tip
    position absolute
    top 0
    left 0
    font 400 11px/24px 'Roboto Slab', serif !important
    padding 0 10px
    background #F3CB1C
    box-shadow 0 1px 2px #8B7308
    color #000 !important
    transform translateY(-100%)
    &:after
        content ''
        position absolute
        bottom -9px
        left 5px
        width 0
        height 0
        border-left 7px solid transparent
        border-right 7px solid transparent
        border-top 10px solid #F3CB1C
    
            
.wpcf7-form-control-wrap
    position static !important
.wpcf7-response-output
    display none !important