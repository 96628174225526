/* Всплывающие окна */
.overlay
    display none
    background-color black
    opacity 0.7
    position fixed
    left 0
    right 0
    top 0
    bottom 0
    z-index 999
.popup
    display none
    position relative
    background-color #f5f2d5
    padding 30px 20px
    text-align center
    z-index 9999
    &-title
        font 400 30px/30px GoudyDecorShodwnC, serif
        margin 0 0 20px
    &-subtitle
        font 300 18px 'Roboto Slab', serif
        margin 0 0 26px
    &-close
        background url(../img/common/x.png) no-repeat
        width 9px
        height 10px
        position absolute
        top 10px
        right 10px
        cursor pointer
    &_thank_you
        font 300 26px/200px PROXIMANOVA, sans-serif; 
        width 430px
        height 200px
    &-callback
        width 258px
.tc_p_close
    background url(img/close.png) no-repeat
    width 35px
    height 35px
    position: absolute
    top 5px
    right 5px
    cursor pointer