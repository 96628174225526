/* Базовые стили */
*
    box-sizing border-box
    
body
    min-width 1170px
    color #020202
    background #F5F2D5

// Если есть фиксированное меню делаем отступ сверху
/*
.body
    &-fixed
        padding 100px 0 0
*/
    
.wrap
    width 1170px
    padding 0 15px
    margin 0 auto
    position relative
    
.btn
    background url(../img/common/btn.png) no-repeat
    text-align center
    width 190px
    height 45px
    font 400 16px 'Roboto Slab', serif
    margin 0 auto 15px
    color #f5f2d5
    border none
    outline none
    cursor pointer