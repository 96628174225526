.head
    margin 22px 0 37px
    padding 4px 0 0
    border-top 2px solid #64635a
    img
        border-top 2px solid #282723
        display block
        margin 0 auto
        
.header
    position relative
    margin 7px 0 47px
    font-size 0
    &-line
        width 100%
        height 7px
        border-top 2px solid #64635a
        border-bottom 2px solid #282723
    &__left
        display inline-block
        vertical-align middle
        padding 6px 0
        width 214px
        font 400 16px 'Roboto Slab', serif
        text-align center
        border-right 1px solid #282723
    &__right
        display inline-block
        vertical-align middle
        padding 6px 0
        width 214px
        font 400 16px 'Roboto Slab', serif
        text-align center
        border-left 1px solid #282723
    &__center
        width 712px
        display inline-block
        vertical-align middle
        text-align center
        font 300 20px 'Roboto Slab', serif
        

.sidebar
    &-left
        float left
        width 204px
        padding 0 13px 0 0
    &-right
        float right
        width 204px
        padding 0 0 0 13px
        
.widget
    &-head
        position relative
        font 400 17px 'Roboto Slab', serif
        text-align center
        margin 0 0 28px
        &:before
            content ''
            display block
            width 140px
            height 1px
            background-color #282723
            margin 0 auto
        &:after
            content ''
            display block
            width 140px
            height 1px
            background-color #282723
            margin 0 auto
        span
            display block
            padding 17px 0
            
    &-about
        &__item
            position relative
            margin 0 0 22px
            font 300 12px 'Roboto Slab', serif
            text-align justify
            &:after
                content ''
                display block
                width 60px
                height 1px
                background-color #282723
                margin 22px auto 0
            &:last-child:after
                content none
            img
                display block
                margin 22px auto 0
                
    &-util
        &__text
            position relative
            font 300 12px 'Roboto Slab', serif
            margin 0 0 27px
            text-align justify
            &:after
                content ''
                display block
                width 60px
                height 1px
                background-color #282723
                margin 27px auto 0
        &__title
            font 300 14px 'Roboto Slab', serif
            margin 0 0 20px
            text-align center
        img
            display block
            margin 0 auto 17px
            
    &-contacts
        &__meta
            margin 0 0 20px
        &__btn
            margin 0 0 15px
        &__link
            font 400 16px 'Roboto Slab', serif
            color #222
            text-decoration none
            display block
            text-align center
            
    &-rewiews
        &__item
            margin 0 0 20px
        &__name
            font 400 15px 'Roboto Slab', serif
            margin 0 0 10px
        &__img
            float left
            max-width 50%
            height auto
            margin 0 15px 2px 0
        &__text
            font 300 12px 'Roboto Slab', serif
            text-align justify
            
    &-why
        &__item
            position relative
            display table
            text-align center
            font 300 14px 'Roboto Slab', serif
            margin 0 auto 25px
            &:before
                content ''
                width 15px
                height 1px
                background-color #282723
                position absolute
                top 50%
                left -25px
            &:after
                content ''
                width 15px
                height 1px
                background-color #282723
                position absolute
                top 50%
                right -25px
            
            
        
            
        
        
.content
    margin 0 204px
    padding 0 30px
    &-left
        position absolute
        top 0
        bottom 0
        left 219px
        width 24px
        background url(../img/common/content-line-body.jpg) repeat-y
        &-top
            position absolute
            top 0
            left 0
            background url(../img/common/content-line-left-top.jpg) no-repeat
            width 24px
            height 24px
        &-center
            position absolute
            top 50%
            left 0
            background url(../img/common/content-line-left-center.jpg) no-repeat
            width 24px
            height 37px
            transform translateY(-50%)
        &-bottom
            position absolute
            bottom 0
            left 0
            background url(../img/common/content-line-left-top.jpg) no-repeat
            width 24px
            height 24px
            transform rotateX(-180deg)
    &-right
        position absolute
        top 0
        bottom 0
        right 219px
        width 24px
        background url(../img/common/content-line-body.jpg) repeat-y
        transform rotate(180deg)
        &-top
            position absolute
            top 0
            left 0
            background url(../img/common/content-line-left-top.jpg) no-repeat
            width 24px
            height 24px
        &-center
            position absolute
            top 50%
            left 0
            background url(../img/common/content-line-left-center.jpg) no-repeat
            width 24px
            height 37px
            transform translateY(-50%)
        &-bottom
            position absolute
            bottom 0
            left 0
            background url(../img/common/content-line-left-top.jpg) no-repeat
            width 24px
            height 24px
            transform rotateX(-180deg)
    &-title
        text-align center
        font 400 50px GoudyDecorShodwnC, serif
        margin 0 0 15px
    &__desc
        font italic 300 18px 'Roboto Slab', serif
        text-align center
        margin 0 0 10px
        mark
            position relative
            display block
            &:before
                content ''
                position absolute
                top 50%
                left 50%
                transform translateX(-50%)
                width 475px
                height 1px
                background-color #282723
            span
                position relative
                display table
                margin 0 auto
                background #F5F2D5
                padding 0 15px
                
.services
    position relative
    padding 25px 0 0
    font-size 0
    &:before
        content ''
        background-color #64635a
        width 2px
        position absolute
        top 30px
        bottom 30px
        left 50%
        transform translateX(-50%)        
    &__item
        position relative
        display inline-block
        vertical-align bottom
        font-size 0
        width 50%
        padding 0 22px
        margin 0 0 20px
        & > img
            float right
            max-width 107px
            margin 0 auto
        &:hover
            .services__eye
                display block
    &__eye
        display none
        background url(../img/common/eye.jpg) no-repeat center center #f5f2d5
        position absolute
        top 0
        bottom 0
        left 1px
        right 1px
        cursor pointer
    &__left
        float left
        width 185px
        img
            margin 0 auto 10px
    &__title
        padding 8px 0
        border-top 2px solid #282723
        border-bottom 2px solid #282723
        text-align center
        font 300 14px 'Roboto Slab', serif
    &__desc
        clear both
        padding 15px 0
        font 300 12px 'Roboto Slab', serif
    &__tel
        font 400 13px 'Roboto Slab', serif
        text-align center
        padding 9px 0 0
        border-top 2px solid #282723
        a
            display block
            font 400 16px 'Roboto Slab', serif
            text-decoration none
            color #020202
        .header-line
            margin 9px 0 0
        
    
    
    
.hww
    padding 30px 0 0
    &-line
        &-top
            content ''
            width 100%
            height 7px
            border-top 2px solid #64635a
            border-bottom 2px solid #282723
        &-bottom
            content ''
            width 100%
            height 7px
            border-top 2px solid #282723
            border-bottom 2px solid #64635a
    &__title
        font 400 20px 'Roboto Slab', serif
        margin 15px 0
        text-transform uppercase
    &__content
        margin 15px 0
        font-size 0
        text-align center
    &-item
        display inline-block
        width 25%
        padding 0 27px
        border-right 2px solid #282723
        &:last-child
            border none
        &__img
            margin 0 auto 10px
        &__or
            display table
            font 400 15px 'Roboto Slab', serif
            margin 10px auto
            padding 5px 10px
            border-top 1px solid #282723
            border-bottom 1px solid #282723
            text-transform uppercase
        &__text
            font 400 15px 'Roboto Slab', serif
            text-transform uppercase
            a
                display block
                font 700 15px 'Roboto Slab', serif
                text-decoration none
                color #020202
        
.footer
    text-align center
    padding 18px 0
    font 300 15px 'Roboto Slab', serif
    
.action-2
    cursor pointer
    